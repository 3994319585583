import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { getDateString } from "@utils";
import { ProfessionalExperience } from "@data/types";
import { ThemeContext } from "@state/Theme";
import { SkillButton, Tile } from "@components";

interface WorkSlideProps extends ProfessionalExperience {
  className?: string;
}

const WorkSlide = (props: WorkSlideProps) => {
  const theme = useContext(ThemeContext);

  // Create date string
  const startDate = getDateString(props.date.start);
  const endDate = props.date.end ? getDateString(props.date.end) : "current";
  const date = `${startDate} - ${endDate}`;

  return (
    <Link
      className="WorkSlide"
      to={`/work/${props.id}`}
      style={{ color: theme.textColor }}
    >
      <Tile className={`WorkSlide-tile ${props.className || ""}`}>
        <div className="WorkSlide-job">
          <h3 className="WorkSlide-company">{props.company.name}</h3>
          <p className="WorkSlide-job-title">{props.jobTitle}</p>
          <p className="WorkSlide-date">{date}</p>
        </div>

        <div className="WorkSlide-skills">
          {props.skills.map((skill) => (
            <SkillButton {...skill} />
          ))}
        </div>

        <div className="WorkSlide-description">
          <p>{props.description}</p>
        </div>
      </Tile>
    </Link>
  );
};

export default WorkSlide;
