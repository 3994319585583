import {Skill} from "../types"

const ai: Skill = {
    name: "AI/ML",
    aliases: [
        "ai/ml",
        "artificial",
        "intelligence",
        "machine learning",
        "machine",
        "learning",
        "ml",
        "genetic",
        "algorithms",
        "algorithm",
        "ga",
        "genetic",
        "algorithm",
    ],
    area: ["Programming"],
    type: "Other",
};

export default ai;
