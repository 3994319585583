import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import {
  SectionHeader,
  ContentCarousel,
  ProjectSlide,
  TextBox,
  Section,
  SectionContent,
  ViewAllButton,
} from "@components";
import { getSortedProjects } from "@utils";

/** 'Projects.' section */
const Projects = () => {
  const theme = useContext(ThemeContext);

  const sortedProjects = getSortedProjects();

  return (
    <Section id="projects">
      <SectionContent>
        <SectionHeader>
          Projects <ViewAllButton />
        </SectionHeader>

        <div className="personal-projects-content">
          <div className="personal-projects-text">
            <div className="personal-projects-text-content">
              <TextBox textAlign="justify">
                Software engineering isn't just my job. It's the one thing that
                I've never been able to put down or lose interest in. Exploring
                new technologies never gets old, and neither does bringing a
                project from my mind out into the real world. Most of the
                projects listed are centered around web dev, since that has
                always been my main focus.
              </TextBox>

              <TextBox textAlign="justify">
                A huge portion of my personal work is centered around learning
                new technologies, most courses I've taken have the code stored
                in my 'Web Dev Bootcamp' repository.
              </TextBox>

              <TextBox textAlign="justify">
                When I'm not doing professional work, I'm usually working on
                exploring new technologies. I find it more fun (and useful in
                the long term) to explore new technologies rather than build a
                business. Nothing here provides serious value to others, but it
                has been a great learning experience for me, to both widen and
                deepen my understanding of the technologies I use to build
                software.
              </TextBox>
            </div>
          </div>

          <div className="personal-projects-content-carousel">
            <ContentCarousel
              slides={sortedProjects.map((project) => (
                <ProjectSlide {...project} />
              ))}
            />
          </div>
        </div>
      </SectionContent>
    </Section>
  );
};

export default Projects;
