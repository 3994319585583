import { Skill } from "../types";

const redux: Skill = {
    name: "Redux",
    aliases: ["redux", "reduxjs", "reactredux", "react"],
    area: ["Front End"],
    type: "Library",
};

export default redux;
