import { Skill } from "../types";

const php: Skill = {
    name: "PHP",
    aliases: ["php", "hypertext", "preprocessor"],
    area: ["Front End", "Programming"],
    type: "Language",
};

export default php;
