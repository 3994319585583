import { Skill } from "../types";

const react: Skill = {
    name: "React",
    aliases: ["react", "react.js", "reactjs"],
    area: ["Front End"],
    type: "Framework",
};

export default react;
