import { createContext } from "react";

import * as ThemeTypes from "./types";

interface ThemeContextState {
  color: ThemeTypes.Color;
  setDarkColor: () => void;
  setLightColor: () => void;
  toggleColor: () => void;

  landingBackgroundColor: string;
  backgroundColor: string;
  inputColor: string;
  headerColor: string;
  textColor: string;
  textColorSmall: string;
  linkColor: string;

  layout: ThemeTypes.Layout;
  setMobileLayout: () => void;
  setDesktopLayout: () => void;
  toggleLayout: () => void;
}

const ThemeContext = createContext<ThemeContextState>({
  color: "dark",
  setDarkColor: () => {},
  setLightColor: () => {},
  toggleColor: () => {},

  landingBackgroundColor: "",
  backgroundColor: "",
  inputColor: "",
  headerColor: "",
  textColor: "",
  textColorSmall: "",
  linkColor: "",

  layout: "mobile",
  setMobileLayout: () => {},
  setDesktopLayout: () => {},
  toggleLayout: () => {},
});

export default ThemeContext;
