import { useLocation } from "react-router-dom";

import "./index.css";
import {
  Page,
  PageContent,
  PageHeader,
  Section,
  SectionContent,
  SkillButton,
  TextBox,
  GoBackButton,
  GitHubLink,
} from "@components";
import Projects from "@data/personalProjects";
import { getTimelineString } from "@utils";

const Project = () => {
  const location = useLocation();

  const projectKey = location.pathname.split("/")[2];
  const selectedProject = Projects[projectKey];
  const { name, github, href } = selectedProject;
  const timeline = getTimelineString(
    selectedProject.date.start,
    selectedProject.date.end
  );

  return (
    <Page id="Project">
      <PageContent>
        <Section className="Project-data">
          <SectionContent>
            <PageHeader style={{ textAlign: "center", textIndent: "0" }}>
              {name}
            </PageHeader>

            <div className="Project-timeline">{timeline}</div>

            <div className="Project-skills">
              {selectedProject.skills.map((skill) => (
                <SkillButton {...skill} />
              ))}
            </div>

            <div className="Project-links">
              {selectedProject.github && <GitHubLink href={github} />}
            </div>
          </SectionContent>
        </Section>

        <Section className="Project-description">
          <SectionContent>
            <TextBox>{selectedProject.description}</TextBox>
          </SectionContent>
        </Section>

        <Section>
          <SectionContent>
            <GoBackButton to="/search" />
          </SectionContent>
        </Section>
      </PageContent>
    </Page>
  );
};

export default Project;
