import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { ThemeContext } from "@state/Theme";

const NavDesktop = () => {
  const theme = useContext(ThemeContext);

  return (
    <div className={`NavDesktop ${theme.layout}`}>
      <Link
        className="NavDesktop-link"
        style={{ color: theme.textColor }}
        to="/#about"
      >
        About
      </Link>
      <Link
        className="NavDesktop-link"
        style={{ color: theme.textColor }}
        to="/#work"
      >
        Work
      </Link>
      <Link
        className="NavDesktop-link"
        style={{ color: theme.textColor }}
        to="/#projects"
      >
        Projects
      </Link>
    </div>
  );
};

export default NavDesktop;
