import { PersonalProject } from "../types";
import skills from "@data/skills";

const webdevbootcamp: PersonalProject = {
  id: "webdevbootcamp",
  name: "Web Dev Bootcamp",
  date: {
    start: new Date("April 1 2019"),
  },
  description: "Personalized bootcamp for exploring new web technologies.",
  main: true,
  skills: [
    skills.react,
    skills.js,
    skills.node,
    skills.mongodb,
    skills.electron,
    skills.ts,
    skills.git,
    skills.docker,
    skills.express,
    skills.css,
    skills.html,
    skills.sass,
    skills.heroku,
    skills.handlebars,
  ],
  github: "https://github.com/RobbyB97/web-dev-bootcamp",
};

export default webdevbootcamp;
