import { PersonalProject } from "../types";
import skills from "@data/skills";

const bitcoinconnecticut: PersonalProject = {
  id: "bitcoinconnecticut",
  name: "Bitcoin Connecticut",
  date: {
    start: new Date("April 1 2023"),
  },
  description:
    "Building a local community to spread awareness and education of Bitcoin.",
  main: true,
  skills: [skills.html, skills.css, skills.js, skills.react],
  href: "https://bitcoinct.org",
};

export default bitcoinconnecticut;
