import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface PageHeaderProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const PageHeader = (props: PageHeaderProps) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="PageHeader">
      <h1
        className="PageHeader-title"
        style={{ color: theme.headerColor, ...props.style }}
      >
        {props.children}
      </h1>
    </div>
  );
};

export default PageHeader;
