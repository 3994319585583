import { createContext } from "react";

interface PageStateContextState {
  searchOpen: boolean;
  toggleSearch: () => void;
  openSearch: () => void;
  closeSearch: () => void;

  mobileNavOpen: boolean;
  toggleMobileNav: () => void;
  openMobileNav: () => void;
  closeMobileNav: () => void;
}

const PageStateContext = createContext<PageStateContextState>({
  searchOpen: false,
  toggleSearch: () => {},
  openSearch: () => {},
  closeSearch: () => {},

  mobileNavOpen: false,
  toggleMobileNav: () => {},
  openMobileNav: () => {},
  closeMobileNav: () => {},
});

export default PageStateContext;
