import { ProfessionalExperienceDict } from "@data/types";
import castamatch from "./castamatch";
import finalsite from "./finalsite";
import peardrive from "./peardrive";
import twdh from "./twdh";
import visionable from "./visionable";
import walkoff from "./walkoff";

const professionalExperience: ProfessionalExperienceDict = {
  castamatch,
  finalsite,
  peardrive,
  twdh,
  visionable,
  walkoff,
};

export default professionalExperience;
