import { useContext, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface PageProps {
  id?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Page = (props: PageProps) => {
  const location = useLocation();
  const navType = useNavigationType();

  const theme = useContext(ThemeContext);

  const id = props.id || "page";

  // Scroll to the top of the page every page render
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      className="Page"
      id={id}
      style={{ backgroundColor: theme.backgroundColor, ...props.style }}
    >
      {props.children}
    </div>
  );
};

export default Page;
