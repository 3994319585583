import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import {
  SectionHeader,
  ContentCarousel,
  WorkSlide,
  Section,
  SectionContent,
  TextBox,
  ViewAllButton,
} from "@components";
import { getSortedWork } from "@utils";

const Work = () => {
  const theme = useContext(ThemeContext);

  const sortedWork = getSortedWork();

  return (
    <Section id="work">
      <SectionContent>
        <SectionHeader>
          Work <ViewAllButton />
        </SectionHeader>

        <div className="professional-experience-content">
          <div className="professional-experience-text">
            <div className="professional-experience-text-content">
              <TextBox textAlign="justify">
                I began my journey in professional full stack web development in
                2018, and have had the opportunity to work with many brilliant
                people across several amazing companies.
              </TextBox>

              <TextBox textAlign="justify">
                University professors have taught me a lot about computer
                science throughout my degree, but not as much as my coworkers
                and team leads have taught me about software engineering. One of
                my favorite parts of working on a team is talking to other
                members of the team; working together to accomplish something
                none of us could have achieved alone.
              </TextBox>
            </div>
          </div>

          <div className="professional-experience-content-carousel">
            <ContentCarousel
              slides={sortedWork.map((work) => (
                <WorkSlide {...work} />
              ))}
            />
          </div>
        </div>
      </SectionContent>
    </Section>
  );
};

export default Work;
