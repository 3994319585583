import { CssBaseline } from "@mui/material";

import "./App.css";
import { StateProvider } from "@state";
import { AppRouter } from "@routers";

function App() {
  return (
    <div className="App">
      <StateProvider>
        <CssBaseline />
        <AppRouter />
      </StateProvider>
    </div>
  );
}

export default App;
