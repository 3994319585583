import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

const TextBox = (props: {
  children: string | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  textAlign?: React.CSSProperties["textAlign"];
}) => {
  const theme = useContext(ThemeContext);

  return (
    <p
      className={`TextBox${` ${props.className}` || ""}`}
      style={{
        color: theme.textColor,
        ...props.style,
        textAlign: props.textAlign || "center",
      }}
    >
      {props.children}
    </p>
  );
};

export default TextBox;
