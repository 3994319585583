import { PersonalProject } from "../types";
import skills from "@data/skills";

const garesearchproject: PersonalProject = {
  id: "garesearchproject",
  name: "GA Research Project",
  date: {
    start: new Date("January 1 2019"),
    end: new Date("March 1 2019"),
  },
  description:
    "Research project comparing different strategies for genetic algorithms.",
  main: false,
  skills: [skills.python, skills.ai],
  github: "https://github.com/RobbyB97/ga-research-project",
};

export default garesearchproject;
