import { personalProjects } from "@data";
import { PersonalProject } from "@data/types";

/**
 * Convert personal projects dictionary to array sorted from newest to oldest
 */
const getSortedProjects = () => {
  // Create array
  const sortedProjects: PersonalProject[] = Object.keys(personalProjects).map(
    (key) => personalProjects[key]
  );

  // Sort
  sortedProjects.sort((a, b) => (a.date.start < b.date.start ? 1 : -1));

  return sortedProjects;
};

export default getSortedProjects;
