import * as DataTypes from "../types";

const light: DataTypes.ColorScheme = {
  landingBackgroundColor: "#F7F5E6",
  backgroundColor: "#FFFDEE",
  inputColor: "#333A56",
  headerColor: "#92A5CF",
  textColor: "#444B67",
  textColorSmall: "#EAEAEA",
  linkColor: "#333A56",
};

export default light;
