import { useLocation } from "react-router-dom";

import "./index.css";
import { getTimelineString } from "@utils";
import ProfessionalExperience from "@data/professionalExperience";
import {
  Page,
  PageHeader,
  PageContent,
  Section,
  SectionContent,
  TextBox,
  GoBackButton,
  SkillButton,
} from "@components";

const Work = () => {
  const location = useLocation();

  const jobKey = location.pathname.split("/")[2];
  const selectedJob = ProfessionalExperience[jobKey];
  const { company, jobTitle } = selectedJob;
  const timeline = getTimelineString(
    selectedJob.date.start,
    selectedJob.date.end
  );

  return (
    <Page id="Work">
      <PageContent>
        <Section className="Work-data">
          <SectionContent>
            <PageHeader style={{ textAlign: "center", textIndent: "0" }}>
              {company.name}
            </PageHeader>

            <div className="Work-title">
              <TextBox>{jobTitle}</TextBox>
            </div>

            <div className="Work-timeline">
              <TextBox>{timeline}</TextBox>
            </div>

            <div className="Work-skills">
              {selectedJob.skills.map((skill) => (
                <SkillButton {...skill} />
              ))}
            </div>
          </SectionContent>
        </Section>

        <Section className="Work-description">
          <SectionContent>
            <TextBox>{selectedJob.description}</TextBox>
          </SectionContent>
        </Section>

        <Section>
          <SectionContent>
            <GoBackButton to="/search" />
          </SectionContent>
        </Section>
      </PageContent>
    </Page>
  );
};

export default Work;
