import { useContext, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface ContentCarouselProps {
  slides: React.ReactNode[];
  style?: React.CSSProperties;
  className?: string;
  size?: {
    width: string;
    height: string;
  };
}

const ContentCarousel = (props: ContentCarouselProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={`ContentCarousel ${props.className || ""}`}>
      <Carousel
        autoPlay={false}
        showStatus={false}
        showThumbs={true}
        swipeable={true}
        emulateTouch={true}
      >
        {props.slides.map((slide) => (
          <div
            className="ContentCarousel-slide"
            style={{
              width: props.size?.width ? props.size.width : "",
              height: props.size?.height ? props.size.height : "",
            }}
          >
            {slide}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ContentCarousel;
