import { Skill } from "../types";

const firebase: Skill = {
    name: "Firebase",
    aliases: ["firebase", "fire", "base"],
    area: ["Back End"],
    type: "Platform",
};

export default firebase;
