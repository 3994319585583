import { Skill } from "../types";

const express: Skill = {
    name: "Express",
    aliases: ["express"],
    area: ["Back End"],
    type: "Framework",
};

export default express;
