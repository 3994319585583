import { useContext } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./index.css";
import { ThemeContext } from "@state/Theme";

const LinkedInLink = (props: { size?: any; color?: any }) => {
  const theme = useContext(ThemeContext);

  const size = props.size || "24px";
  const color = props.color || theme.textColor;

  return (
    <div className="LinkedInLink">
      <a href="https://www.linkedin.com/in/robbyb97/" style={{ color: color }}>
        <LinkedInIcon color="inherit" fontSize="medium" />
      </a>
    </div>
  );
};

export default LinkedInLink;
