import { PersonalProject } from "../types";
import skills from "@data/skills";

const badmedia: PersonalProject = {
  id: "badmedia",
  name: "B.A.D. Media",
  date: {
    start: new Date("January 1 2019"),
    end: new Date("March 1 2019"),
  },
  description:
    "Creates a custom news feed page based on user's personal interests.",
  main: false,
  skills: [skills.python, skills.html, skills.css],
  github: "https://github.com/RobbyB97/BADMedia",
};

export default badmedia;
