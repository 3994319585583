import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import {
  SectionHeader,
  TextBox,
  ImageCarousel,
  Section,
  SectionContent,
  Divider,
  ReadMore,
  A,
} from "@components";
import { images } from "@data";

const About = () => {
  const theme = useContext(ThemeContext);

  return (
    <Section id="about">
      <SectionContent>
        <SectionHeader>About</SectionHeader>

        <div className="about-content">
          <div className="about-text">
            <div className="about-text-content">
              <Divider />

              <TextBox textAlign="justify">
                I'm a full stack developer specializing in React with around 3
                years of professional experience and a Bachelor's degree in
                Computer Science. I recently started a group called "Bitcoin
                Connecticut" to help spread awareness and education of Bitcoin
                in my local area. Check it out{" "}
                <A href="https://bitcoinct.org">here</A>.
              </TextBox>

              <TextBox textAlign="justify">
                I started programming seriously in 2014 when I was 17 years old,
                and my goal is to use my experience to build clean, user
                friendly front ends (and back ends) for your business.
              </TextBox>

              <TextBox textAlign="justify">
                Apart from programming I like to train parkour, work out, play
                guitar and play videogames with friends. But most importantly I
                love spending time with my dog, Deku.
              </TextBox>

              <Divider />
            </div>
          </div>

          <div className="about-images">
            <ImageCarousel
              slides={[
                {
                  image: images.dekucostumedesktop,
                  legend: "Deku on Halloween",
                },
                {
                  image: images.dekusittingdesktop,
                  legend: "The cutest puppy ever.",
                },
              ]}
            />
          </div>
        </div>
      </SectionContent>
    </Section>
  );
};

export default About;
