import { PersonalProject } from "../types";
import skills from "@data/skills";

const solotwitch: PersonalProject = {
  id: "solotwitch",
  name: "SoloTwitch",
  date: {
    start: new Date("August 1 2020"),
    end: new Date("September 1 2020"),
  },
  description: "A twitch site redesign, done through a chrome extension.",
  main: false,
  skills: [skills.crx, skills.sass, skills.css],
  href: "https://solotwitchwebsite.web.app",
  github: "https://github.com/RobbyB97/solotwitch",
};

export default solotwitch;
