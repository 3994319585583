import { Skill } from "../types";

const webpack: Skill = {
    name: "Webpack",
    aliases: ["webpack", "webpackjs"],
    area: ["Programming"],
    type: "Dev Op",
};

export default webpack;
