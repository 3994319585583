import { ProfessionalExperience } from "../types";
import skills from "../skills";
import { finalsite as logo } from "../../@data/images";

const finalsite: ProfessionalExperience = {
  id: "finalsite",
  jobTitle: "Front-end developer",
  company: {
    name: "Finalsite",
    logo,
  },
  date: {
    start: new Date("July 2019"),
    end: new Date("December 2019"),
  },
  description:
    "Resolved bugs and implemented ADA compliant designs for public school websites",
  skills: [skills.gulp, skills.css, skills.sass, skills.html, skills.js],
};

export default finalsite;
