import { Skill } from "../types";

const html: Skill = {
    name: "HTML5",
    aliases: ["html5", "hyper", "text", "markup", "language", "html"],
    area: ["Front End"],
    type: "Language",
};

export default html;
