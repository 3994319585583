import { Skill } from "../types";

const docker: Skill = {
    name: "Docker",
    aliases: ["docker", "container", "kubernetes"],
    area: ["Programming"],
    type: "Dev Op",
};

export default docker;
