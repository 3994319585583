import { ProfessionalExperience } from "../types";
import skills from "../skills";
import { finalsite as logo } from "../../@data/images";

const walkoff: ProfessionalExperience = {
  id: "walkoff",
  jobTitle: "Full-stack developer",
  company: {
    name: "WalkOff",
    logo,
  },
  date: {
    start: new Date("November 2020"),
    end: new Date("February 2021"),
  },
  description: "Built company website.",
  skills: [
    skills.html,
    skills.js,
    skills.css,
    skills.sass,
    skills.react,
    skills.redux,
    skills.next,
    skills.webpack,
    skills.node,
    skills.firebase,
  ],
};

export default walkoff;
