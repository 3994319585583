import * as DataTypes from "@data/types";

/** Returns true if given string/substring matches skill */
const matchSkill = (searchTerm: string, skill: DataTypes.Skill) => {
  const cleanSearchTerm = searchTerm.toLowerCase();

  if (skill.name.includes(cleanSearchTerm)) return true;
  if (skill.type.includes(cleanSearchTerm)) return true;

  let matchesAlias = false;
  skill.aliases.forEach((alias) => {
    if (alias.includes(cleanSearchTerm)) matchesAlias = true;
  });
  if (matchesAlias) return true;

  let matchesArea = false;
  skill.area.forEach((area) => {
    if (area.includes(cleanSearchTerm)) matchesArea = true;
  });
  if (matchesArea) return true;

  return false;
};

export default matchSkill;
