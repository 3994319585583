import { Skill } from "../types";

const js: Skill = {
    name: "JavaScript",
    aliases: ["javascript", "js", "java", "script"],
    area: ["Programming", "Front End"],
    type: "Language",
};

export default js;
