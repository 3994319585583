import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { Skill } from "@data/types";
import { ThemeContext } from "@state/Theme";
import { SearchContext } from "@state/Search";
import { dark } from "@data/colorScheme";

interface SkillButtonProps extends Skill {
  className?: string;
  clickable?: boolean;
}

const SkillButton = (props: SkillButtonProps) => {
  const theme = useContext(ThemeContext);
  const search = useContext(SearchContext);

  const className = `SkillButton 
    ${props.className || ""} 
    ${props.clickable ? "clickable" : ""}`;

  // Update search term if clickable
  const onClick = () => {
    if (!props.clickable) return;

    search.setTerm(props.name);
  };

  return (
    <div
      className={className}
      style={{ backgroundColor: theme.headerColor }}
      onClick={onClick}
    >
      <p className="SkillButton-name" style={{ color: dark.textColor }}>
        {props.name}
      </p>
    </div>
  );
};

export default SkillButton;
