import { Skill } from "../types";

const node: Skill = {
    name: "Node.JS",
    aliases: ["node.js", "node", "nodejs", "npm"],
    area: ["Programming", "Back End"],
    type: "Framework",
};

export default node;
