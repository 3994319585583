import { useContext, useEffect, useState } from "react";
import { AiOutlineDesktop } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { PageStateContext } from "@state/PageState";
import { dark } from "@data/colorScheme";

/** Button for controlling design layout */
const LayoutThemeSwitcher = () => {
  const theme = useContext(ThemeContext);
  const pageState = useContext(PageStateContext);

  const [color, setColor] = useState(theme.textColor);

  const switchLayoutTheme = () => {
    theme.toggleLayout();
  };

  // Enforce dark color theme on mobile nav open
  useEffect(() => {
    if (theme.layout === "mobile" && pageState.mobileNavOpen === true) {
      setColor(dark.textColor);
    } else {
      setColor(theme.textColor);
    }
  }, [theme.layout, pageState.mobileNavOpen, theme.textColor]);

  return (
    <div className={`LayoutThemeSwitcher ${theme.layout}`}>
      <button
        onClick={() => switchLayoutTheme()}
        className="LayoutThemeSwitcher-button"
      >
        {theme.layout === "desktop" ? (
          <AiOutlineDesktop color={color} size={"24px"} />
        ) : (
          <BsPhone color={color} size={"24px"} />
        )}
      </button>
    </div>
  );
};

export default LayoutThemeSwitcher;
