import { PersonalProject } from "../types";
import skills from "@data/skills";

const weatherapp: PersonalProject = {
  id: "weatherapp",
  name: "Weather App",
  date: {
    start: new Date("April 1 2019"),
    end: new Date("April 1 2019"),
  },
  description:
    "Node.js app to track weather anywhere using weather / location APIs",
  main: false,
  skills: [skills.node],
  github: "https://github.com/RobbyB97/weather-app-heroku",
};

export default weatherapp;
