import "./index.css";

interface SectionContentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const SectionContent = (props: SectionContentProps) => {
  return (
    <div
      className={`SectionContent ${props.className || ""}`}
      style={props.style || {}}
    >
      {props.children}
    </div>
  );
};

export default SectionContent;
