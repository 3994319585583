import { PersonalProject } from "../types";
import skills from "@data/skills";

const reduxboilerplate: PersonalProject = {
  id: "reduxboilerplate",
  name: "Redux Boilerplate",
  date: {
    start: new Date("June 1 2020"),
    end: new Date("September 1 2020"),
  },
  description: "React boilerplate with built-in redux boilerplate.",
  main: false,
  skills: [
    skills.js,
    skills.webpack,
    skills.sass,
    skills.css,
    skills.html,
    skills.react,
    skills.redux,
    skills.node,
  ],
  github: "https://github.com/RobbyB97/ReduxBoilerplate",
};

export default reduxboilerplate;
