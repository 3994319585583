import React, { useState } from "react";
import { SearchContext } from ".";

interface SearchProviderProps {
  children: React.ReactNode;
}

const SearchProvider = (props: SearchProviderProps) => {
  const [term, setTerm] = useState("");

  /** Clear search term */
  const clear = () => {
    setTerm("");
  };

  return (
    <SearchContext.Provider value={{ term, setTerm, clear }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
