import { Skill } from "../types";

const git: Skill = {
    name: "Git",
    aliases: ["git", "gitlab", "github", "gh", "vc", "version control", "version", "control"],
    area: ["Programming"],
    type: "Dev Op",
};

export default git;
