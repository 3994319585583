import { Skills } from "../types";
import { default as ai } from "./ai";
import { default as crx } from "./crx";
import { default as css } from "./css";
import { default as docker } from "./docker";
import { default as electron } from "./electron";
import { default as express } from "./express";
import { default as firebase } from "./firebase";
import { default as git } from "./git";
import { default as gulp } from "./gulp";
import { default as handlebars } from "./handlebars";
import { default as heroku } from "./heroku";
import { default as html } from "./html";
import { default as js } from "./js";
import { default as mongodb } from "./mongodb";
import { default as next } from "./next";
import { default as php } from "./php";
import { default as python } from "./python";
import { default as rails } from "./rails";
import { default as react } from "./react";
import { default as redux } from "./redux";
import { default as sass } from "./sass";
import { default as ts } from "./ts";
import { default as webpack } from "./webpack";
import { default as wordpress } from "./wordpress";
import { default as node } from "./node";

const skills = {
  ai: ai,
  crx: crx,
  css: css,
  docker: docker,
  electron: electron,
  express: express,
  firebase: firebase,
  git: git,
  gulp: gulp,
  handlebars: handlebars,
  heroku: heroku,
  html: html,
  js: js,
  mongodb: mongodb,
  next: next,
  node: node,
  php: php,
  python: python,
  rails: rails,
  react: react,
  redux: redux,
  sass: sass,
  ts: ts,
  webpack: webpack,
  wordpress: wordpress,
};

export default skills;
