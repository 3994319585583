import { useState, useEffect, useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { SearchContext } from "@state/Search";
import {
  Page,
  PageContent,
  SearchBar,
  Section,
  SectionContent,
  ProjectSlide,
  WorkSlide,
  GoBackButton,
  SkillButton,
} from "@components";
import skills from "@data/skills";
import * as utils from "@utils";
import jobs from "@data/professionalExperience";
import projects from "@data/personalProjects";

const Search = () => {
  const theme = useContext(ThemeContext);
  const search = useContext(SearchContext);

  const [relevantJobs, setRelevantJobs] = useState<string[]>([]);
  const [relevantProjects, setRelevantProjects] = useState<string[]>([]);

  // Set relevant jobs and projects on search term update
  useEffect(() => {
    // Show everything when search bar is empty
    if (search.term === "") {
      setRelevantJobs(Object.keys(jobs));
      setRelevantProjects(Object.keys(projects));
      return;
    }

    // Update relevant results
    const results = utils.matchJobsAndProjects(search.term);
    setRelevantJobs(results.work);
    setRelevantProjects(results.projects);
  }, [search.term]);

  return (
    <Page id="Search">
      <PageContent>
        <Section className="Search-actions">
          <SectionContent>
            <GoBackButton />
            <br />
            <SearchBar />
            <br />
            <div className="Search-actions-skills">
              {Object.keys(skills).map((skill) => (
                // @ts-ignore
                <SkillButton {...skills[skill]} clickable={true} />
              ))}
            </div>

            <div className="Search-results">
              <div className="Search-results-content">
                {relevantJobs.map((job) => (
                  <WorkSlide className="Search-slide" {...jobs[job]} />
                ))}
                {relevantProjects.map((project) => (
                  <ProjectSlide
                    className="Search-slide"
                    {...projects[project]}
                  />
                ))}
              </div>
            </div>
          </SectionContent>
        </Section>
      </PageContent>
    </Page>
  );
};

export default Search;
