import { Skill } from "../types";

const mongodb: Skill = {
    name: "MongoDB",
    aliases: ["mongodb", "mongoose", "mongo", "mongo db"],
    area: ["Back End"],
    type: "Language",
};

export default mongodb;
