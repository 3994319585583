import { PersonalProject } from "../types";
import skills from "@data/skills";

const ytiframe: PersonalProject = {
  id: "ytiframe",
  name: "YT iframe",
  date: {
    start: new Date("February 1 2019"),
    end: new Date("September 1 2021"),
  },
  description: "A responsive HTML page generator for YouTube channgels.",
  main: false,
  skills: [skills.python, skills.html, skills.css],
  pypi: "https://pypi.org/project/yt-iframe",
  github: "https://github.com/RobbyB97/yt-iframe-python",
};

export default ytiframe;
