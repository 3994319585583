import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface SectionHeaderProps {
  children: React.ReactNode | string;
  className?: string;
}

const SectionHeader = (props: SectionHeaderProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={`SectionHeader ${props.className || ""}`}>
      <h2 className="SectionHeader-title" style={{ color: theme.headerColor }}>
        {props.children}
      </h2>
    </div>
  );
};

export default SectionHeader;
