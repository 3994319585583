import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home, Project, Search, Wildcard, Work } from "@pages";
import jobs from "@data/professionalExperience";
import projects from "@data/personalProjects";
import { Header, Footer } from "@components";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />

        {/* Work */}
        {Object.keys(jobs).map((jobKey) => (
          <Route path={`/work/${jobs[jobKey].id}`} element={<Work />} />
        ))}

        {/* Projects */}
        {Object.keys(projects).map((projectKey) => (
          <Route
            path={`/project/${projects[projectKey].id}`}
            element={<Project />}
          />
        ))}

        <Route path="*" element={<Wildcard />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
