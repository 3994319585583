import "./index.css";
import { Page } from "@components";

const Wildcard = () => {
  return (
    <Page id="wildcard">
      <div>404 page</div>
    </Page>
  );
};

export default Wildcard;
