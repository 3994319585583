import React from "react";

import { ThemeProvider } from "./Theme";
import { PageStateProvider } from "./PageState";
import { SearchProvider } from "./Search";

/** Wrapper component for Context API state */
const StateProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <div id="state-provider">
      <SearchProvider>
        <PageStateProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </PageStateProvider>
      </SearchProvider>
    </div>
  );
};

export default StateProvider;
