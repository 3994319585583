import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface SectionProps {
  children: React.ReactNode;
  id?: string;
  className?: string;
  fullPage?: boolean;
  background?: React.CSSProperties["background"];
}

const Section = (props: SectionProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      id={props.id || ""}
      className={`Section ${props.className || ""}`}
      style={{
        color: theme.textColor,
        minHeight: props.fullPage ? "100vh" : "none",
        background: props.background || "transparent",
      }}
    >
      {props.children}
    </div>
  );
};

export default Section;
