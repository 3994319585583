import { Skill } from "../types";

const heroku: Skill = {
    name: "Heroku",
    aliases: ["heroku"],
    area: ["Back End"],
    type: "Platform",
};

export default heroku;
