import { PersonalProjects } from "@data/types";
import badmedia from "./badmedia";
import bitcoinconnecticut from "./bitcoinconnecticut";
import chatapp from "./chatapp";
import dogtreatlauncher from "./dogtreatlauncher";
import expensify from "./expensify";
import flappybirdai from "./flappybirdai";
import garesearchproject from "./garesearchproject";
import indecisionapp from "./indecisionapp";
import libpyn from "./libpyn";
import reduxboilerplate from "./reduxboilerplate";
import solotwitch from "./solotwitch";
import taskmanager from "./taskmanager";
import weatherapp from "./weatherapp";
import webdevbootcamp from "./webdevbootcamp";
import ytiframe from "./ytiframe";

const personalProjects: PersonalProjects = {
  badmedia,
  bitcoinconnecticut,
  chatapp,
  dogtreatlauncher,
  expensify,
  flappybirdai,
  garesearchproject,
  indecisionapp,
  libpyn,
  reduxboilerplate,
  solotwitch,
  taskmanager,
  weatherapp,
  webdevbootcamp,
  ytiframe,
};

export default personalProjects;
