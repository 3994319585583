import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { SearchContext } from "@state/Search";

interface SearchBarProps {
  className?: string;
}

const SearchBar = (props: SearchBarProps) => {
  const theme = useContext(ThemeContext);
  const search = useContext(SearchContext);

  const updateSearchTerm = (e: any) => {
    search.setTerm(e.target.value);
  };

  const clear = () => {
    search.clear();
  };

  return (
    <div className={`SearchBar ${props.className || ""}`}>
      <input
        type="text"
        value={search.term}
        placeholder="Search work"
        onChange={(e) => updateSearchTerm(e)}
        style={{
          backgroundColor: theme.landingBackgroundColor,
          color: theme.textColor,
        }}
      />

      {search.term.length > 0 && (
        <button
          className="SearchBar-clear"
          style={{ background: theme.backgroundColor, color: theme.textColor }}
          onClick={clear}
        >
          x
        </button>
      )}
    </div>
  );
};

export default SearchBar;
