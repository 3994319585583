import { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { dark } from "@data/colorScheme";

const ImageCarousel = (props: {
  slides: {
    image: string;
    legend?: string;
  }[];
  imageStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  autoPlay?: boolean;
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className="ImageCarousel"
      style={{ ...props.style, background: theme.headerColor }}
    >
      <Carousel
        autoPlay={props.autoPlay}
        showThumbs={false}
        showArrows={true}
        infiniteLoop={true}
        swipeable={true}
        emulateTouch={true}
      >
        {props.slides.map((slide) => (
          <div className="ImageCarousel-slide">
            {slide.legend ? (
              <p
                className="ImageCarousel-legend"
                style={{ color: dark.textColor }}
              >
                {slide.legend}
              </p>
            ) : (
              <></>
            )}

            <img
              className="ImageCarousel-image"
              src={slide.image}
              alt={slide.legend!}
              style={props.imageStyle}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
