import { useContext, useCallback } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { images } from "@data";
import { ThemeContext } from "@state/Theme";
import { LinkRow, Section } from "@components";

const Landing = () => {
  const theme = useContext(ThemeContext);

  return (
    <Section id="landing">
      <div className="landing-card">
        <img
          className="landing-selfie"
          src={images.selfieCrypto}
          alt="A selfie of me"
          style={{
            border: `2px solid ${theme.headerColor}`,
          }}
        />
        <h2 className="landing-header">Hi, I'm Robby 👋</h2>
        <h3 className="landing-tagline">I'm a web dev</h3>

        <LinkRow className="landing-links" />

        <div className="landing-calls-to-action">
          <div
            className="landing-call-to-action"
            style={{ border: `1px solid ${theme.headerColor}` }}
          >
            <Link
              to="/#work"
              style={{
                color: theme.headerColor,
                backgroundColor: theme.landingBackgroundColor,
              }}
            >
              View my work
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Landing;
