import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { PageStateContext } from "@state/PageState";

const NavMobile = () => {
  const theme = useContext(ThemeContext);
  const pageState = useContext(PageStateContext);

  /** Toggle menu (open / close buttons) */
  const toggleMenu = (e: any, newState?: boolean) => {
    e.preventDefault();

    if (newState === undefined) pageState.toggleMobileNav();
    else if (newState === false) pageState.closeMobileNav();
    else pageState.openMobileNav();
  };

  /** Turn menu off when using internal link */
  const useInternalLink = () => {
    pageState.closeMobileNav();
  };

  return (
    <div className={`NavMobile ${theme.layout}`}>
      <div className="NavMobile-icon">
        <IconButton
          className="NavMobile-icon-button"
          onClick={(e) => toggleMenu(e)}
        >
          {pageState.mobileNavOpen ? (
            <CloseIcon style={{ color: theme.textColorSmall, padding: 0 }} />
          ) : (
            <MenuIcon style={{ color: theme.textColor, padding: 0 }} />
          )}
        </IconButton>
      </div>

      <div
        className={`NavMobile-menu ${pageState.mobileNavOpen ? "active" : ""}`}
        style={{ backgroundColor: theme.headerColor }}
      >
        <div className="NavMobile-menu-header">
          <IconButton
            className="NavMobile-icon-close"
            onClick={(e) => toggleMenu(e, false)}
          ></IconButton>
        </div>

        <div className="NavMobile-menu-list">
          <div className="NavMobile-menu-item">
            <Link
              to="/#about"
              onClick={useInternalLink}
              style={{ color: theme.textColorSmall }}
            >
              About
            </Link>
          </div>

          <div className="NavMobile-menu-item">
            <Link
              to="/#work"
              onClick={useInternalLink}
              style={{ color: theme.textColorSmall }}
            >
              Work
            </Link>
          </div>

          <div className="NavMobile-menu-item">
            <Link
              to="/#projects"
              onClick={useInternalLink}
              style={{ color: theme.textColorSmall }}
            >
              Projects
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
