import * as DataTypes from "@data/types";
import jobs from "@data/professionalExperience";
import projects from "@data/personalProjects";
import { matchSkills } from "@utils";

/** Return relevant jobs and projects by search term */
const matchJobsAndProjects = (searchTerm: string) => {
  const cleanSearchTerm = searchTerm.toLowerCase();

  let matchingJobs: string[] = [];
  let matchingProjects: string[] = [];

  const jobKeys = Object.keys(jobs);
  const projectKeys = Object.keys(projects);

  // Find relevant jobs
  jobKeys.forEach((jobKey) => {
    const job = jobs[jobKey];
    let jobMatches = false;

    if (jobKey.includes(cleanSearchTerm)) jobMatches = true;
    if (matchSkills(cleanSearchTerm, job.skills)) jobMatches = true;
    if (job.company.name.includes(cleanSearchTerm)) jobMatches = true;
    if (job.jobTitle.toLowerCase().includes(cleanSearchTerm)) jobMatches = true;
    if (job.description.toLowerCase().includes(cleanSearchTerm))
      jobMatches = true;

    if (jobMatches) matchingJobs.push(jobKey);
  });

  // Find relevant projects
  projectKeys.forEach((projectKey) => {
    const project = projects[projectKey];
    let projectMatches = false;

    if (project.name.includes(cleanSearchTerm)) projectMatches = true;
    if (matchSkills(cleanSearchTerm, project.skills)) projectMatches = true;
    if (project.description.includes(cleanSearchTerm)) projectMatches = true;

    if (projectMatches) matchingProjects.push(projectKey);
  });

  return {
    work: matchingJobs,
    projects: matchingProjects,
  };
};

export default matchJobsAndProjects;
