import { useContext, useEffect, useState } from "react";
import { HiOutlineSun, HiOutlineMoon } from "react-icons/hi";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { PageStateContext } from "@state/PageState";
import { dark } from "@data/colorScheme";

interface ColorThemeSwitcherProps {
  className?: string;
}

const ColorThemeSwitcher = (props: ColorThemeSwitcherProps) => {
  const theme = useContext(ThemeContext);
  const pageState = useContext(PageStateContext);

  const [color, setColor] = useState(theme.textColor);

  const switchColorTheme = () => {
    theme.toggleColor();
  };

  // Enforce dark color theme on mobile nav open
  useEffect(() => {
    if (theme.layout === "mobile" && pageState.mobileNavOpen === true) {
      setColor(dark.textColor);
    } else {
      setColor(theme.textColor);
    }
  }, [theme.layout, pageState.mobileNavOpen, theme.textColor]);

  return (
    <div
      className={`ColorThemeSwitcher ${props.className || ""} ${theme.layout}`}
    >
      <button
        onClick={() => switchColorTheme()}
        className="ColorThemeSwitcher-button"
      >
        {theme.color === "dark" ? (
          <HiOutlineMoon color={color} size={"24px"} />
        ) : (
          <HiOutlineSun color={color} size={"24px"} />
        )}
      </button>
    </div>
  );
};

export default ColorThemeSwitcher;
