import { Skill } from "../types";

const next: Skill = {
    name: "Next.JS",
    aliases: ["nextjs", "next", "js", "next.js"],
    area: ["Front End", "Back End"],
    type: "Framework",
};

export default next;
