import { ProfessionalExperience } from "../types";
import skills from "../skills";

const visionable: ProfessionalExperience = {
  id: "visionable",
  company: {
    name: "Visionable",
    href: "https://visionable.com",
  },
  jobTitle: "Full-stack developer",
  date: {
    start: new Date("August 2021"),
    end: new Date("November 2022"),
  },
  description:
    "Building architecture for version 3 of visionable client software",
  skills: [
    skills.html,
    skills.css,
    skills.js,
    skills.ts,
    skills.react,
    skills.next,
  ],
};

export default visionable;
