import { PersonalProject } from "../types";
import skills from "@data/skills";

const dogtreatlauncher: PersonalProject = {
  id: "dogtreatlauncher",
  name: "Dog Treat Launcher",
  date: {
    start: new Date("August 1 2022"),
    end: new Date("January 1 2023"),
  },
  description:
    "A robot controlled through a web browser that can aim and launch dog treats, so I can play with my dog from anywhere.",
  main: true,
  skills: [skills.html, skills.css, skills.js, skills.python, skills.react],
  github: "https://github.com/RobbyB97/DogTreatLauncher",
};

export default dogtreatlauncher;
