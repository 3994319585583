import { PersonalProject } from "../types";
import skills from "@data/skills";

const expensify: PersonalProject = {
  id: "expensify",
  name: "Expensify",
  date: {
    start: new Date("July 1 2019"),
    end: new Date("November 1 2019"),
  },
  description: "React app for expense tracking.",
  main: false,
  skills: [
    skills.react,
    skills.html,
    skills.webpack,
    skills.js,
    skills.sass,
    skills.heroku,
    skills.redux,
    skills.express,
  ],
  href: "https://hidden-peak-31636.herokuapp.com",
  github: "https://github.com/RobbyB97/expensify-app",
};

export default expensify;
