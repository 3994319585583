import { useContext, useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { colorScheme } from "@data";

const { dark } = colorScheme;

interface MailLinkProps {
  size?: string;
  color?: string;
}

const MailLink = (props: MailLinkProps) => {
  const theme = useContext(ThemeContext);

  const [active, setActive] = useState(false);

  const size = props.size || "24px";

  const toggle = (e: any) => {
    e.preventDefault();
    setActive(!active);
  };

  return (
    <div className="MailLink">
      <button
        className="MailLink-button"
        onClick={(e) => toggle(e)}
        style={{ color: props.color }}
      >
        <EmailIcon color="inherit" fontSize="medium" />
      </button>

      {active ? (
        <div
          className="MailLink-modal"
          style={{
            borderBottom: `5px solid ${theme.headerColor}`,
          }}
        >
          <div
            className="MailLink-modal-tail"
            style={{
              color: dark.textColor,
              borderBottom: `10px solid ${theme.headerColor}`,
            }}
          />

          <div
            className="MailLink-modal-content"
            style={{ background: theme.headerColor }}
          >
            <p style={{ color: dark.textColor }}>
              Email me:&nbsp;
              <a
                href="mailto:bergersr97@gmail.com"
                style={{ color: dark.linkColor }}
              >
                bergersr97@gmail.com
              </a>
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MailLink;
