import * as DataTypes from "../types";

const dark: DataTypes.ColorScheme = {
  landingBackgroundColor: "#21262D",
  backgroundColor: "#161B22",
  inputColor: "#120C21",
  /*   headerColor: "#A907A4", */
  headerColor: "#52658F",
  textColor: "#E8E8E8",
  textColorSmall: "#D6D6D6",
  linkColor: "#6EB9EB",
};

export default dark;
