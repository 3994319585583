import { ProfessionalExperience } from "../types";
import skills from "../skills";

const castamatch: ProfessionalExperience = {
  id: "castamatch",
  company: {
    name: "Castamatch",
  },
  jobTitle: "CTO",
  date: {
    start: new Date("July 1 2020"),
    end: new Date("May 1 2021"),
  },
  description:
    "Built a full-stack web application that provided a monetizable streaming platform for sports organizations.",
  skills: [
    skills.html,
    skills.css,
    skills.js,
    skills.node,
    skills.mongodb,
    skills.ts,
    skills.react,
    skills.sass,
    skills.redux,
    skills.express,
  ],
};

export default castamatch;
