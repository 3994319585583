import React, { useState, useEffect } from "react";

import { PageStateContext } from ".";

const PageStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  /** Toggle search */
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  /** Open search */
  const openSearch = () => {
    setSearchOpen(true);
  };

  /** Close search */
  const closeSearch = () => {
    setSearchOpen(false);
  };

  /** Toggle mobile nav */
  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  /** Turn mobile nav on */
  const openMobileNav = () => {
    setMobileNavOpen(true);
  };

  /** Turn mobile nav off */
  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  return (
    <PageStateContext.Provider
      value={{
        searchOpen,
        mobileNavOpen,

        toggleSearch,
        openSearch,
        closeSearch,

        toggleMobileNav,
        openMobileNav,
        closeMobileNav,
      }}
    >
      {children}
    </PageStateContext.Provider>
  );
};

export default PageStateProvider;
