import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./index.css";
import {
  ColorThemeSwitcher,
  LayoutThemeSwitcher,
  NavMenu,
  HomeButton,
  SearchButton,
} from "@components";
import { ThemeContext } from "@state/Theme";

const Header = () => {
  const theme = useContext(ThemeContext);
  const location = useLocation();

  const [showHeaderBG, setShowHeaderBG] = useState(true);

  /** Determine header background based on scroll location */
  const onScroll = (e: any) => {
    if (location.pathname.split("#")[0] === "/") {
      const winHeight =
        window.innerHeight ||
        (document.documentElement || document.body).clientHeight;

      const scrollAmount = window.scrollY;
      if (scrollAmount > 200) setShowHeaderBG(true);
      else setShowHeaderBG(false);
    } else setShowHeaderBG(true);
  };

  return (
    <div
      className={`Header ${theme.layout} ${theme.color}`}
      style={{
        color: theme.textColor,
        background: showHeaderBG ? theme.landingBackgroundColor : "transparent",
      }}
    >
      <HomeButton show={showHeaderBG} />

      <div className="Header-divider"></div>

      <SearchButton />
      <ColorThemeSwitcher />
      <LayoutThemeSwitcher />
      <NavMenu />
    </div>
  );
};

export default Header;
