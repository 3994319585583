import { Skill } from "../types";

const electron: Skill = {
    name: "Electron",
    aliases: ["electron", "electronjs", "electron js"],
    area: ["Programming"],
    type: "Framework",
};

export default electron;
