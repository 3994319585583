import { Skill } from "../types";

const gulp: Skill = {
    name: "Gulp",
    aliases: ["gulp"],
    area: ["Programming"],
    type: "Dev Op",
};

export default gulp;
