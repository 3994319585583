import { Skill } from "../types";

const rails: Skill = {
    name: "Ruby on Rails",
    aliases: ["ruby", "on", "rails", "ruby on rails"],
    area: ["Back End", "Programming"],
    type: "Language",
};

export default rails;
