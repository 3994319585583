import React, { useState, useEffect } from "react";

import { colorScheme } from "@data";
import { ThemeContext } from ".";
import * as ThemeTypes from "./types";

const light = colorScheme.light;
const dark = colorScheme.dark;

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  // Theme settings
  const [color, setColor] = useState<ThemeTypes.Color>("dark");
  const [layout, setLayout] = useState<ThemeTypes.Layout>("mobile");

  // Color schemes
  const [landingBackgroundColor, setLandingBackgroundColor] = useState(
    light.landingBackgroundColor
  );
  const [backgroundColor, setBackgroundColor] = useState(dark.backgroundColor);
  const [inputColor, setInputColor] = useState(dark.inputColor);
  const [headerColor, setHeaderColor] = useState(dark.headerColor);
  const [textColor, setTextColor] = useState(dark.textColor);
  const [textColorSmall, setTextColorSmall] = useState(dark.textColorSmall);
  const [linkColor, setLinkColor] = useState(dark.linkColor);

  // Set default values
  useEffect(() => {
    // Set default layout
    const device = navigator.userAgent;
    const mobileDevices = /android|iphone|kindle|ipad/i;
    const isMobile = mobileDevices.test(device);
    if (isMobile) setLayout("mobile");
    else setLayout("desktop");

    // @ts-ignore | Set default color theme
    const sessionColor: ThemeTypes.Color | null = localStorage.getItem("color");
    if (sessionColor && sessionColor === "dark") setDarkColor();
    else setLightColor();
  }, []);

  /** Set color theme to dark */
  const setDarkColor = () => {
    localStorage.setItem("color", "dark");
    setColor("dark");
    setLandingBackgroundColor(dark.landingBackgroundColor);
    setBackgroundColor(dark.backgroundColor);
    setInputColor(dark.inputColor);
    setHeaderColor(dark.headerColor);
    setTextColor(dark.textColor);
    setTextColorSmall(dark.textColorSmall);
    setLinkColor(dark.linkColor);
  };

  /** Set color theme to light */
  const setLightColor = () => {
    localStorage.setItem("color", "light");
    setColor("light");
    setLandingBackgroundColor(light.landingBackgroundColor);
    setBackgroundColor(light.backgroundColor);
    setInputColor(light.inputColor);
    setHeaderColor(light.headerColor);
    setTextColor(light.textColor);
    setTextColorSmall(light.textColorSmall);
    setLinkColor(light.linkColor);
  };

  /** Toggle color theme */
  const toggleColor = () => {
    if (color === "dark") setLightColor();
    else setDarkColor();
  };

  /** Set UI layout to mobile */
  const setMobileLayout = () => {
    setLayout("mobile");
  };

  /** Set UI layout to desktop */
  const setDesktopLayout = () => {
    setLayout("desktop");
  };

  /** Toggle UI layout */
  const toggleLayout = () => {
    if (layout === "mobile") setDesktopLayout();
    else setMobileLayout();
  };

  return (
    <ThemeContext.Provider
      value={{
        color,
        setDarkColor,
        setLightColor,
        toggleColor,

        layout,
        setMobileLayout,
        setDesktopLayout,
        toggleLayout,

        landingBackgroundColor,
        backgroundColor,
        inputColor,
        headerColor,
        textColor,
        textColorSmall,
        linkColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
