import { Skill } from "../types";

const ts: Skill = {
    name: "TypeScript",
    aliases: ["typescript", "ts", "tsx", "type script"],
    area: ["Programming"],
    type: "Language",
};

export default ts;
