import { PersonalProject } from "../types";
import skills from "@data/skills";

const flappybirdai: PersonalProject = {
  id: "flappybirdai",
  name: "Flappy Bird AI",
  date: {
    start: new Date("May 1 2019"),
    end: new Date("May 1 2019"),
  },
  description:
    "Restored, fully functional version of CodeBullet's flappy bird ai neural network.",
  main: false,
  skills: [skills.ai, skills.js, skills.html, skills.heroku],
  href: "https://flappy-bird-ai.herokuapp.com",
  github: "https://github.com/RobbyB97/Flappy-Bird-AI",
};

export default flappybirdai;
