import { useContext } from "react";
import GitHubIcon from "@mui/icons-material/GitHub";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface GitHubLinkProps {
  size?: any;
  color?: any;
  href?: string;
}

const GitHubLink = (props: GitHubLinkProps) => {
  const theme = useContext(ThemeContext);

  // Configure
  const color = props.color || theme.textColor;
  const href = props.href || "https://github.com/HopeTS";

  return (
    <div className="GitHubLink">
      <a href={href} style={{ color: color }} title="View on GitHub">
        <GitHubIcon color="inherit" fontSize="medium" />
      </a>
    </div>
  );
};

export default GitHubLink;
