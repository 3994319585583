import { PersonalProject } from "../types";
import skills from "@data/skills";

const chatapp: PersonalProject = {
  id: "chatapp",
  name: "Chat App",
  date: {
    start: new Date("June 1 2019"),
    end: new Date("June 1 2019"),
  },
  description: "Basic node.js chat application.",
  main: false,
  skills: [
    skills.html,
    skills.css,
    skills.js,
    skills.node,
    skills.webpack,
    skills.express,
    skills.heroku,
  ],
  href: "https://chat-app-robbyb97.herokuapp.com",
  github:
    "https://github.com/RobbyB97/web-dev-bootcamp/blob/master/node/chat-app",
};

export default chatapp;
