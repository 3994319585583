import { HashLink as Link } from "react-router-hash-link";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useState, useEffect } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { PageStateContext } from "@state/PageState";
import { dark } from "@data/colorScheme";
import { Divider } from "@components";

interface SearchButtonProps {
  className?: string;
  style?: React.CSSProperties;
}

const SearchButton = (props: SearchButtonProps) => {
  const theme = useContext(ThemeContext);
  const pageState = useContext(PageStateContext);

  const [color, setColor] = useState(theme.textColor);

  // Enforce dark color theme on mobile nav open
  useEffect(() => {
    if (theme.layout === "mobile" && pageState.mobileNavOpen === true) {
      setColor(dark.textColor);
    } else {
      setColor(theme.textColor);
    }
  }, [theme.layout, pageState.mobileNavOpen, theme.textColor]);

  return (
    <Link
      className={`SearchButton ${props.className || ""} ${theme.layout}`}
      to="/search"
      color={color}
    >
      <Divider />
      <SearchIcon className="SearchButton-icon" sx={{ color }} />
      <Divider />
    </Link>
  );
};

export default SearchButton;
