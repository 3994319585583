import * as DataTypes from "@data/types";
import { matchSkill } from "@utils";

/** Returns true if given string/substring matches skills list */
const matchSkills = (searchTerm: string, skills: DataTypes.Skill[]) => {
  const cleanSearchTerm = searchTerm.toLowerCase();
  let matchesSkill = false;

  skills.forEach((skill) => {
    if (matchesSkill) return;
    if (matchSkill(cleanSearchTerm, skill)) matchesSkill = true;
  });
  if (matchesSkill) return true;

  return false;
};

export default matchSkills;
