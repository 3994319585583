import { PersonalProject } from "../types";
import skills from "@data/skills";

const taskmanager: PersonalProject = {
  id: "taskmanager",
  name: "Task Manager",
  date: {
    start: new Date("May 1 2019"),
    end: new Date("June 1 2019"),
  },
  description: "Simple to-do list with basic authentication system",
  main: false,
  skills: [skills.node, skills.mongodb, skills.express, skills.html, skills.js],
  github:
    "https://github.com/RobbyB97/web-dev-bootcamp/blob/master/node/task-manager",
};

export default taskmanager;
