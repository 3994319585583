import { ProfessionalExperience } from "../types";
import skills from "../skills";

const peardrive: ProfessionalExperience = {
  id: "peardrive",
  company: {
    name: "111 Studios",
  },
  jobTitle: "Software Engineer",
  date: {
    start: new Date("May 1 2023"),
  },
  description:
    "Building a free, open source service in Node.js that allows users to create their own self-hosted cloud storage system.",
  skills: [skills.node, skills.mongodb, skills.ts, skills.react],
};

export default peardrive;
