import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

const Tile = (props: {
  children: React.ReactNode;
  className?: string;
  color?: string;
  padding?: string;
  borderRadius?: string;
  style?: React.CSSProperties;
}) => {
  const theme = useContext(ThemeContext);

  // Determine styles
  const backgroundColor = props.color || theme.landingBackgroundColor;
  const padding = props.padding || "12px";
  const borderRadius = props.borderRadius || "6px";

  return (
    <div
      className={`Tile${props.className ? ` ${props.className}` : ""}`}
      style={{ backgroundColor, padding, borderRadius, ...props.style }}
    >
      {props.children}
    </div>
  );
};

export default Tile;
