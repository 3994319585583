import { Skill } from "../types";

const wordpress: Skill = {
    name: "WordPress",
    aliases: ["wordpress", "wp", "word", "press"],
    area: ["Front End", "Back End"],
    type: "Platform",
};

export default wordpress;
