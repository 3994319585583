import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { NavDesktop } from "@components";
import { NavMobile } from "@components";

const NavMenu = () => {
  const theme = useContext(ThemeContext);

  return (
    <div className="NavMenu">
      {theme.layout === "mobile" ? <NavMobile /> : <NavDesktop />}
    </div>
  );
};

export default NavMenu;
