import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface GoBackButtonProps {
  to?: string;
}

const GoBackButton = (props: GoBackButtonProps) => {
  const theme = useContext(ThemeContext);

  return (
    <Link className="GoBackButton" to={props.to || "/"}>
      <p
        className="GoBackButton-content"
        style={{
          background: theme.landingBackgroundColor,
          color: theme.headerColor,
        }}
      >
        ← Go Back
      </p>
    </Link>
  );
};

export default GoBackButton;
