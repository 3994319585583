import { Skill } from "../types";

const handlebars: Skill = {
    name: "Handlebars",
    aliases: ["handlebars", "handle", "bars", "hbs"],
    area: ["Front End"],
    type: "Language",
};

export default handlebars;
