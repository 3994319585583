import { ProfessionalExperience } from "../types";
import skills from "@data/skills";
import { twdh as logo } from "../images";

const twdh: ProfessionalExperience = {
  id: "twdh",
  jobTitle: "Junior Web Developer",
  company: {
    name: "The Web Design Hub",
    logo,
  },
  date: {
    start: new Date("October 2018"),
    end: new Date("May 2019"),
  },
  description: "Added features to PHP sites, developed WordPress plugins",
  skills: [
    skills.php,
    skills.css,
    skills.js,
    skills.html,
    skills.git,
    skills.wordpress,
  ],
};

export default twdh;
