import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { GitHubLink, LinkedInLink, MailLink } from "@components";

interface LinkRowProps {
  className?: string;
}

const LinkRow = (props: LinkRowProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={`LinkRow ${props.className || ""}`}>
      <GitHubLink color={theme.textColor} />
      <LinkedInLink color={theme.textColor} />
      <MailLink color={theme.textColor} />
    </div>
  );
};

export default LinkRow;
