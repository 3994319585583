import { createContext } from "react";

interface SearchContext {
  term: string;
  setTerm: (newState: string) => void;

  clear: () => void;
}

const SearchContext = createContext<SearchContext>({
  term: "",
  setTerm: (newState) => {},
  clear: () => {},
});

export default SearchContext;
