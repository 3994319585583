import { Skill } from "../types";

const python: Skill = {
    name: "Python",
    aliases: ["python", "py", "py2", "py3"],
    area: ["Programming", "Back End"],
    type: "Language",
};

export default python;
