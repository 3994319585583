import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { TextBox, HomeButton, LinkRow } from "@components";

const Footer = () => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className="Footer"
      style={{ backgroundColor: theme.landingBackgroundColor }}
    >
      <div className="Footer-content">
        <div className="Footer-logo">
          <HomeButton show={true} />
        </div>

        <div className="Footer-divider"></div>

        <LinkRow className="Footer-links" />

        <div className="Footer-copyright">
          <TextBox style={{ fontSize: "14px" }}>
            © Robby Bergers 2018 - 2024
          </TextBox>
        </div>
      </div>
    </div>
  );
};

export default Footer;
