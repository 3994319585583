import {Skill} from "../types"

const crx: Skill = {
    name: "Chrome extension",
    aliases: ["crx", "chrome", "extension", "chrome extension"],
    area: ["Front End", "Programming"],
    type: "Other",
};

export default crx;
