import { PersonalProject } from "../types";
import skills from "@data/skills";

const libpyn: PersonalProject = {
  id: "libpyn",
  name: "libpyn",
  date: {
    start: new Date("January 1 2019"),
    end: new Date("February 1 2019"),
  },
  description:
    "Web scraper to programmatically gather all your favorite podcasts on a SPA.",
  pypi: "https://pypi.org/project/libpyn",
  github: "https://github.com/RobbyB97/libpyn",
  skills: [skills.python, skills.html, skills.css],
  main: false,
};

export default libpyn;
