import { useContext } from "react";
import { TbBold } from "react-icons/tb";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { ThemeContext } from "@state/Theme";

/** Top left button, returns to landing */
const HomeButton = ({ show }: { show: boolean }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className="HomeButton">
      <Link
        className={`HomeButton-link ${show ? "show" : ""}`}
        to="/#landing"
        style={{
          cursor: show ? "pointer" : "",
        }}
      >
        <TbBold
          className="HomeButton-icon"
          size={"40px"}
          style={{
            color: theme.headerColor,
          }}
        />
      </Link>
    </div>
  );
};

export default HomeButton;
