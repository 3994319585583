import { professionalExperience } from "@data";
import { ProfessionalExperience } from "@data/types";

/**
 * Convert work dictionary to array sorted from newest to oldest
 */
const getSortedWork = () => {
  // Create array
  const sortedWork: ProfessionalExperience[] = Object.keys(
    professionalExperience
  ).map((key) => professionalExperience[key]);

  // Sort
  sortedWork.sort((a, b) => (a.date.start < b.date.start ? 1 : -1));

  return sortedWork;
};

export default getSortedWork;
