import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./index.css";
import { PersonalProject } from "@data/types";
import { ThemeContext } from "@state/Theme";
import { getDateString } from "@utils";
import { SkillButton, Tile, GitHubLink } from "@components";

interface ProjectSlideProps extends PersonalProject {
  className?: string;
}

/** Carousel slide for personal projects */
const ProjectSlide = (props: ProjectSlideProps) => {
  const theme = useContext(ThemeContext);

  // Create date string
  const startDate = getDateString(props.date.start);
  const endDate = props.date.end ? getDateString(props.date.end) : "current";
  const date = `${startDate} - ${endDate}`;

  return (
    <Link
      className={`ProjectSlide ${props.className || ""}`}
      to={`/project/${props.id}`}
      style={{ color: theme.textColor }}
    >
      <Tile className="ProjectSlide-tile">
        <div className="ProjectSlide-info">
          <div className="ProjectSlide-name-date">
            <h3 className="ProjectSlide-name">{props.name}</h3>
            <p className="ProjectSlide-date">{date}</p>
          </div>

          <div className="ProjectSlide-links">
            {props.github ? <GitHubLink href={props.github} /> : <></>}
          </div>

          <div className="ProjectSlide-skills">
            {props.skills.map((skill) => (
              <SkillButton {...skill} />
            ))}
          </div>
        </div>

        <div className="ProjectSlide-description">
          <p>{props.description}</p>
        </div>
      </Tile>
    </Link>
  );
};

export default ProjectSlide;
