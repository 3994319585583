import { Skill } from "../types";

const sass: Skill = {
    name: "SASS",
    aliases: ["sass", "scss", "sassycss", "sassy", "css"],
    area: ["Front End"],
    type: "Language",
};

export default sass;
