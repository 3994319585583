import "./index.css";

interface PageContentProps {
  children: React.ReactNode;
}

const PageContent = (props: PageContentProps) => {
  return <div className="PageContent">{props.children}</div>;
};

export default PageContent;
