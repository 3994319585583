import "./index.css";
import { BackgroundParticles } from "@components";
import { About, Landing, Projects, Work } from "@sections";

import { Page } from "@components";

const Home = () => {
  return (
    <Page id="home" style={{ backgroundColor: "transparent" }}>
      <BackgroundParticles />
      <Landing />
      <About />
      <Work />
      <Projects />
    </Page>
  );
};

export default Home;
