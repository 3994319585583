import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";

interface AProps {
  children: React.ReactNode | string;
  href: string;
  style?: React.CSSProperties;
}

const A = (props: AProps) => {
  const theme = useContext(ThemeContext);

  const style = {
    color: theme.linkColor,
    ...props.style,
  };

  return (
    <a className="A" href={props.href} style={style}>
      {props.children}
    </a>
  );
};

export default A;
