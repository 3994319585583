import { NavLink } from "react-router-dom";
import { useContext } from "react";

import "./index.css";
import { ThemeContext } from "@state/Theme";
import { Divider } from "@components";

const ViewAllButton = () => {
  const theme = useContext(ThemeContext);
  return (
    <NavLink
      to="/search"
      className="ViewAllButton"
      style={{ color: theme.textColor }}
    >
      <Divider />
      View all →
      <Divider />
    </NavLink>
  );
};

export default ViewAllButton;
