import { useContext, useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import "./index.css";
import { ThemeContext } from "@state/Theme";

const BackgroundParticles = () => {
  const theme = useContext(ThemeContext);

  const particlesInit = useCallback(async (engine: any) => {
    console.log(engine);
    loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    console.log(container);
  }, []);

  return (
    <div className="BackgroundParticles">
      <Particles
        id="BackgroundParticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: theme.backgroundColor,
          },
          fpsLimit: 120,
          smooth: true,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: theme.headerColor,
            },
            links: {
              color: theme.headerColor,
              distance: 150,
              enable: true,
              opacity: 0.4,
              width: 0.5,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: true,
              speed: 5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  );
};

export default BackgroundParticles;
