import { PersonalProject } from "../types";
import skills from "@data/skills";

const indecisionapp: PersonalProject = {
  id: "indecisionapp",
  name: "Indecision App",
  date: {
    start: new Date("July 1 2019"),
    end: new Date("July 1 2019"),
  },
  description: "React app that selects a random name from a given list.",
  main: false,
  href: "https://indecision-robbyb97.web.app/",
  github:
    "https://github.com/RobbyB97/web-dev-bootcamp/tree/master/react/indecision-app",
  skills: [
    skills.react,
    skills.css,
    skills.sass,
    skills.js,
    skills.redux,
    skills.webpack,
    skills.html,
    skills.node,
    skills.firebase,
  ],
};

export default indecisionapp;
