import { getDateString } from "@utils";

/** Get timeline string from dates (ex. January 1 2019 - November 1 2020) */
const getTimelineString = (start: Date, end?: Date) => {
  const timeStart = getDateString(start);
  const timeEnd = end ? getDateString(end) : "current";

  return `${timeStart} - ${timeEnd}`;
};

export default getTimelineString;
